
import Vue from 'vue'
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import fetcher from '@/services/fetcher'

type Responsavel = {
  NOME: string;
  DATANASCIMENTO: Date;
  FUNCAO: string;
  TELEFONE: string;
  CELULAR: string;
  CEP: string;
  ENDERECO: string;
  COMPLEMENTO: string;
  BAIRRO: string;
  CIDADE: string;
  UF: string;
  EMAIL: string;
  TIMEFUTEBOL: string;
  CHAVEPIX: string;
};

type Cliente = {
  CODCLI: number;
  CLIENTE: string;
  TELEFONE: string;
  EMAIL: string;
};

type ViaCEP = {
  ENDERECO: string;
  BAIRRO: string;
  COMPLEMENTO: string;
  CIDADE: string;
  UF: string;
};

type DadosFormulario = {
  retorno: boolean;
  mensagem: string;
  cliente: Cliente;
  responsavel: Responsavel;
  notificacaoWhatsapp: boolean;
  codigoVerificacao?: number;
  cepValido: boolean;
};

type RetornoMsgVerificao = {
  STATUS: boolean;
  MSG: string;
};

@Component
export default class CadastroUsuario extends Vue {
  baseUrl = 'https://server.grupopalmed.com.br:4001'
  // baseUrl = 'http://localhost:4002'
  dadosValidos = true;
  posicaoAba = 1;
  alerta = '';
  cnpjcpf = '';
  aguardandoGravacao = false;
  validadorFormulario = {
    vazio: [(v: string) => !!v || 'Campo é Obrigatório'],
    data: [
      (d: string) => !!d || 'Campo é Obrigatório',
      (d: string) => this.validarData(d) || 'Data Inválida'
    ],
    telefone: [(t: string) => this.validarTelefone(t) || 'Telefone Inválido'],
    cep: [(c: string) => this.validarCEP(c) || 'Telefone Inválido'],
    email: [(e: string) => this.validarEmail(e) || 'E-mail inválido']
  };

  dadosFormulario: DadosFormulario = {
    retorno: false,
    mensagem: '',
    cliente: {
      CODCLI: 0,
      CLIENTE: '',
      TELEFONE: '',
      EMAIL: ''
    },
    responsavel: {
      NOME: '',
      DATANASCIMENTO: undefined,
      FUNCAO: '',
      TELEFONE: '',
      CELULAR: '',
      CEP: '',
      ENDERECO: '',
      COMPLEMENTO: '',
      BAIRRO: '',
      CIDADE: '',
      UF: '',
      EMAIL: '',
      TIMEFUTEBOL: '',
      CHAVEPIX: ''
    },
    notificacaoWhatsapp: false,
    cepValido: false
  };

  exibirAlerta (alerta: string): void {
    this.alerta = alerta
  }

  // Validando Passo 1 do Processo de Cadastro
  async validarPasso1 (): Promise<void> {
    this.exibirAlerta('')
    if (this.cnpjcpf === '') {
      this.exibirAlerta('Informe um CNPJ Válido')
      return
    }
    await fetcher<{
      STATUS: boolean;
      MSG: string;
      DATA: {
        CODCLI: number;
        CLIENTE: string;
        TELEFONE: string;
        EMAIL: string;
      };
    }>(
      'POST',
      `${this.baseUrl}/auth/cliente`,
      {
        cnpj: this.cnpjcpf,
        codevento: 0
      }
    )
      .then((res) => {
        if (res.ok && res.data?.STATUS) {
          this.posicaoAba = 2
          this.dadosFormulario = {
            retorno: res.data?.STATUS,
            mensagem: res.data?.MSG,
            cliente: res.data?.DATA,
            responsavel: {
              NOME: '',
              DATANASCIMENTO: undefined,
              FUNCAO: '',
              TELEFONE: '',
              CELULAR: '',
              CEP: '',
              ENDERECO: '',
              COMPLEMENTO: '',
              BAIRRO: '',
              CIDADE: '',
              UF: '',
              EMAIL: '',
              TIMEFUTEBOL: '',
              CHAVEPIX: ''
            },
            notificacaoWhatsapp: false,
            cepValido: false
          }
        } else {
          this.exibirAlerta(res.data?.MSG)
        }
      })
      .catch((err) => {
        this.exibirAlerta((err as Error).message)
      })
  }

  // Validando Passo 3 do Processo de cadastro
  async validarPasso2 (): Promise<void> {
    this.exibirAlerta('')
    if (!this.validarEmail(this.dadosFormulario.cliente.EMAIL)) {
      this.exibirAlerta('E-Mail é obrigatório ou está inválido')
      return
    }
    this.posicaoAba = 3
  }

  // Validando Passo 3 do Processo de cadastro
  async validarPasso3 (): Promise<void> {
    this.exibirAlerta('')
    if (this.dadosFormulario.responsavel.NOME === '') {
      this.exibirAlerta('Nome é obrigatório')
      return
    }
    if (this.dadosFormulario.responsavel.DATANASCIMENTO) {
      if (
        !this.validarData(
          this.dadosFormulario.responsavel.DATANASCIMENTO.toString()
        )
      ) {
        this.exibirAlerta('Data Inválida')
        return
      }
    } else {
      this.exibirAlerta('Data Inválida')
      return
    }
    if (this.dadosFormulario.responsavel.FUNCAO === '') {
      this.exibirAlerta('Função é obrigatório')
      return
    }
    if (this.dadosFormulario.responsavel.CELULAR === '') {
      this.exibirAlerta('Celular é obrigatório')
      return
    }
    if (this.dadosFormulario.responsavel.TELEFONE !== '') {
      if (!this.validarTelefone(this.dadosFormulario.responsavel.TELEFONE)) {
        this.exibirAlerta('Telefone não está preechido corretamente')
        return
      }
    }
    if (this.dadosFormulario.responsavel.CEP === '') {
      this.exibirAlerta('CEP é obrigatório')
      return
    }
    if (
      !this.dadosFormulario.responsavel.ENDERECO ||
      this.dadosFormulario.responsavel.ENDERECO === ''
    ) {
      this.exibirAlerta('Endereço é obrigatório')
      return
    }
    if (
      !this.dadosFormulario.responsavel.COMPLEMENTO ||
      this.dadosFormulario.responsavel.COMPLEMENTO === ''
    ) {
      this.exibirAlerta('Complemento é obrigatório')
      return
    }
    if (
      !this.dadosFormulario.responsavel.BAIRRO ||
      this.dadosFormulario.responsavel.BAIRRO === ''
    ) {
      this.exibirAlerta('Bairro é obrigatório')
      return
    }
    if (
      !this.dadosFormulario.responsavel.CIDADE ||
      this.dadosFormulario.responsavel.CIDADE === ''
    ) {
      this.exibirAlerta('Cidade é obrigatório')
      return
    }
    if (
      !this.dadosFormulario.responsavel.UF ||
      this.dadosFormulario.responsavel.UF === ''
    ) {
      this.exibirAlerta('UF é obrigatório')
      return
    }
    if (!this.validarEmail(this.dadosFormulario.responsavel.EMAIL)) {
      this.exibirAlerta('E-Mail é obrigatório ou está inválido')
      return
    }
    if (this.dadosFormulario.responsavel.TIMEFUTEBOL === '') {
      this.exibirAlerta('Time de Futebol é obrigatório')
      return
    }
    if (this.dadosFormulario.responsavel.CHAVEPIX === '') {
      this.exibirAlerta('Chave Pix é obrigatório')
      return
    }
    this.posicaoAba = 4
  }

  // Validando Passo 4 do Processo de cadastro
  async validarPasso4 (reenviarCodigo: boolean): Promise<void> {
    this.exibirAlerta('')
    this.dadosFormulario.codigoVerificacao = undefined
    if (this.cnpjcpf === '') {
      this.exibirAlerta('CNPJ Válido')
      return
    }
    const res = await fetcher<RetornoMsgVerificao>(
      'POST',
      `${this.baseUrl}/auth/codigoVerificacao`,
      {
        cnpj: this.cnpjcpf,
        nome: this.dadosFormulario.responsavel.NOME,
        celular: this.dadosFormulario.responsavel.CELULAR,
        novocodigo: reenviarCodigo
      }
    )
    if (res.data.STATUS) {
      this.posicaoAba = 5
    } else {
      this.exibirAlerta(res.data.MSG)
    }
  }

  // Validando Passo 5 do Processo de cadastro
  async validarPasso5 (): Promise<void> {
    this.exibirAlerta('')
    if (this.cnpjcpf === '') {
      this.exibirAlerta('CNPJ Válido')
      return
    }
    this.aguardandoGravacao = true
    try {
      const res = await fetcher<RetornoMsgVerificao>(
        'POST',
        `${this.baseUrl}/auth/validarCodigoVerificacao`,
        {
          cnpj: this.cnpjcpf,
          nome: this.dadosFormulario.responsavel.NOME,
          celular: this.dadosFormulario.responsavel.CELULAR,
          codigo: this.dadosFormulario.codigoVerificacao
        }
      )
      if (res.data.STATUS) {
        // envia o cadastro se tudo ok então finaliza o processo
        await this.validarPasso6().then((retorno: boolean) => {
          if (retorno) {
            this.posicaoAba = 6
          }
        })
      } else {
        this.exibirAlerta(res.data.MSG)
      }
    } finally {
      this.aguardandoGravacao = false
    }
  }

  // Validando Passo 6 Cadastrar o cliente
  async validarPasso6 (): Promise<boolean> {
    this.exibirAlerta('')
    const res = await fetcher<RetornoMsgVerificao>(
      'POST',
      `${this.baseUrl}/auth/novoUsuarioPortal`,
      {
        IDUSUARIOPORTAL: 0,
        IDCLIENTEWINTHOR: this.dadosFormulario.cliente.CODCLI,
        RAZAOSOCIAL: this.dadosFormulario.cliente.CLIENTE,
        TELEFONE: this.dadosFormulario.cliente.TELEFONE,
        EMAIL: this.dadosFormulario.cliente.EMAIL,
        RESP_NOME: this.dadosFormulario.responsavel.NOME,
        RESP_DTNASCIMENTO: this.dadosFormulario.responsavel.DATANASCIMENTO,
        RESP_FUNCAO: this.dadosFormulario.responsavel.FUNCAO,
        RESP_TELEFONE: this.dadosFormulario.responsavel.TELEFONE,
        RESP_CELULAR: this.dadosFormulario.responsavel.CELULAR,
        RESP_CEP: this.dadosFormulario.responsavel.CEP,
        RESP_ENDERECO: this.dadosFormulario.responsavel.ENDERECO,
        RESP_ENDERECO_COMPL: this.dadosFormulario.responsavel.COMPLEMENTO,
        RESP_BAIRRO: this.dadosFormulario.responsavel.BAIRRO,
        RESP_CIDADE: this.dadosFormulario.responsavel.CIDADE,
        RESP_UF: this.dadosFormulario.responsavel.UF,
        RESP_EMAIL: this.dadosFormulario.responsavel.EMAIL,
        RESP_TIMEFUTEBOL: this.dadosFormulario.responsavel.TIMEFUTEBOL,
        RESP_CHAVEPIX: this.dadosFormulario.responsavel.CHAVEPIX,
        MENSAGEM_WHATSAPP: this.dadosFormulario.codigoVerificacao ? 'S' : 'N'
      }
    )
    if (!res.data.STATUS) {
      this.exibirAlerta(res.data.MSG)
      return false
    } else {
      return true
    }
  }

  // validar data
  validarData (dateStr: string): boolean {
    if (dateStr === undefined) {
      return false
    }
    return moment(dateStr, 'DD/MM/YYYY', true).isValid()
  }

  // validar telefone
  validarTelefone (telefone: string): boolean {
    if (telefone || telefone !== '') {
      var regex = new RegExp(
        '^\\([1-9]{2}\\) (?:[2-8]|9[1-9])[0-9]{3}\\-[0-9]{4}$'
      )
      return regex.test(telefone)
    } else {
      return true
    }
  }

  validarEmail (email: string): boolean {
    var regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    return regex.test(email)
  }

  // validar CEP
  async validarCEP (cep: string): Promise<boolean> {
    this.dadosFormulario.cepValido = false
    if (!cep) {
      return false
    }
    // Nova variável "cep" somente com dígitos.
    const cepSomenteDigito = cep.toString().replace(/\D/g, '')

    if (cepSomenteDigito !== '') {
      // Expressão regular para validar o CEP.
      const validacep = /^[0-9]{8}$/
      // Valida o formato do CEP.
      if (validacep.test(cepSomenteDigito)) {
        const res = await fetcher<{ STATUS: boolean; ENDERECO: ViaCEP }>(
          'POST',
          `${this.baseUrl}/util/cep`,
          {
            CEP: cepSomenteDigito
          }
        )
        if (res.data.STATUS) {
          this.dadosFormulario.responsavel.ENDERECO =
            res.data.ENDERECO.ENDERECO
          this.dadosFormulario.responsavel.BAIRRO = res.data.ENDERECO.BAIRRO
          this.dadosFormulario.responsavel.COMPLEMENTO =
            res.data.ENDERECO.COMPLEMENTO
          this.dadosFormulario.responsavel.CIDADE = res.data.ENDERECO.CIDADE
          this.dadosFormulario.responsavel.UF = res.data.ENDERECO.UF
          this.dadosFormulario.cepValido =
            !!this.dadosFormulario.responsavel.ENDERECO
          return this.dadosFormulario.cepValido
        } else {
          this.exibirAlerta('Não foi localizado o CEP Informado')
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
